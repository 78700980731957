
import BaseFormScope from './BaseFormScope'

export default {
  name: 'BaseFormInput',
  extends: BaseFormScope,
  props: {
    type: {
      type: String,
      default: 'text',
      validator: type => ['text', 'password', 'email', 'number', 'search'].includes(type),
    },

    mask: {
      type: String,
      default: '',
    },

    maskPlaceholder: {
      type: String,
      default: '',
    },

    maxlength: {
      type: [String, Number],
      default: undefined,
    },
  },

  methods: {
    focus () {
      this.$refs.input.focus()
    },
  },
}
